<template>
  <v-snackbar v-model="snackbar" color="text" bottom :timeout="-1">
    {{ $t('user.updatesAvailable') }}
    <app-text-btn color="white" title="user.refreshNow" class="mx-4" underlined @click="refresh" />

    <template #action>
      <app-icon-btn color="white" icon="mdi-close" size="1rem" @click="snackbar = false" />
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'RefreshSnackbar',

  data: () => ({
    snackbar: false,
    registration: null
  }),

  methods: {
    refresh() {
      if (!this.registration || !this.registration.waiting) return;

      this.registration.waiting.postMessage('SKIP_WAITING');
    }
  },

  created() {
    this.$eventHub.$on('content-update', ($event) => {
      this.registration = $event;
      this.snackbar = true;
    });
  },

  beforeDestroy() {
    this.$eventHub.$off('content-update');
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-snack__content {
  @include body-1-font-style;
  display: flex;
  align-items: baseline;
}
</style>
